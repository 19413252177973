.button {
  line-height: 2.5;
  align-items: baseline;

  .value {
    font-size: 2.25rem;
  }
  .unit {
    font-size: 1.5rem;
  }
}
